@font-face {
	font-family: 'Gilroy';
	src: url('../font/Gilroy-ExtraBold.woff2') format('woff2'), url('../font/Gilroy-ExtraBold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gilroy';
	src: url('../font/Gilroy-Light.woff2') format('woff2'), url('../font/Gilroy-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

html {
	scroll-behavior: smooth;
}

body {
	font-family: 'Gilroy';
}

.sc-button.style.wallet span::before {
	font-family: 'Font Awesome 5 Pro';
	content: '\f555';
}

.sc-button.style.play span::before {
	font-family: 'Font Awesome 5 Pro';
	content: '\f04b';
}

.flat-title-page.style2 {
	height: 108vh;
	/*1050px*/
}

.flat-title-page.style2 .overlay-top {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url('../oceanland/overlay-top.png');
	background-position: center top;
	background-repeat: no-repeat;
	background-size: 100%;
	z-index: 0;
}

.flat-title-page.style2 .overlay-bottom {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url('../oceanland/overlay-bottom.png');
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: 100%;
	z-index: 1;
}

.flat-title-page.style2 .overlay-coconut {
	position: absolute;
	bottom: 23%;
	right: 12%;
	width: 277px;
	height: 277px;
	background-image: url('../oceanland/overlay-coconut.png');
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: cover;
	z-index: 2;
}

.flat-title-page h1.heading {
	color: #197aff !important;
	font-weight: bold;
	font-size: 72px;
}

.flat-title-page h2.heading {
	color: #0a3166 !important;
	font-weight: bold;
	font-size: 72px;
}

.flat-title-page p.sub-heading {
	color: #495059 !important;
	font-size: 21px;
}

#main-nav > ul > li > a,
#main-nav > ul > li > a:hover {
	color: #0a3166;
	text-transform: none;
}

.sc-button.btn-primary,
.modal-body .btn.btn-primary {
	color: #fff;
	background-color: #197aff !important;
	border-color: #197aff !important;
}

.sc-button.btn-dark {
	color: #fff;
	background-color: #0a3166;
	border-color: #0a3166;
}

.sc-button.btn-primary:hover,
.sc-button.btn-primary:hover span,
.sc-button.btn-primary:hover span::before,
.modal-body .btn.btn-primary:hover {
	color: #fff;
	background-color: #197aff !important;
	border-color: #197aff !important;
}

.sc-button.btn-dark:hover,
.sc-button.btn-dark:hover span,
.sc-button.btn-dark:hover span::before {
	color: #fff;
	background-color: #0a3166;
	border-color: #0a3166;
}

.flat-title-page .swiper-button-next,
.flat-title-page .swiper-button-prev {
	position: absolute;
	background: #ffffff;
	border-radius: 20px !important;
	width: 50px !important;
	height: 50px !important;
	left: inherit;
	top: 85%;
}

.flat-title-page .swiper-button-next {
	right: 11% !important;
}

.flat-title-page .swiper-button-prev {
	right: 15% !important;
}

.flat-title-page .swiper-button-prev.btn-slide-prev::after {
	font-family: 'Font Awesome 5 Pro';
	content: '\f060';
	color: #0a3166;
}

.flat-title-page .swiper-button-next.btn-slide-next::after {
	font-family: 'Font Awesome 5 Pro';
	content: '\f061';
	color: #0a3166;
}

.flat-title-page .swiper-button-prev.btn-slide-prev:hover,
.flat-title-page .swiper-button-next.btn-slide-next:hover {
	color: #fff !important;
	background-color: #0a3166;
}

.bg-box-icon-color {
	background-color: #0a3166;
	border-radius: 24px;
	z-index: 3;
	position: absolute;
	left: 50%;
	transform: translate(-50%, -40%);
}

.sc-box-icon {
	text-align: left;
	border-right: 1px solid rgba(255, 255, 255, 0.5);
	width: calc(20% - 33px);
}

.sc-box-icon:last-child {
	border-right: 0;
}

.sc-box-icon h1.heading {
	color: #fff !important;
	font-weight: bold;
	font-size: 16px;
	font-weight: bold;
}

.sc-box-icon h2.heading {
	color: #fff !important;
	font-weight: bold;
	font-size: 24px;
}

.sc-box-icon p.sub-heading {
	color: #fff !important;
	font-weight: bold;
	font-size: 12px;
}

.sc-box-icon .tf-text.s1 {
	font-weight: bold;
	color: #24ca49;
}

.sc-box-icon .heading {
	margin-bottom: 7px;
}

.sc-box-icon-inner {
	margin: 0 auto;
}

.sc-box-icon-bg {
	position: absolute;
	top: -50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.tf-section.bg-blue {
	background-image: url('../oceanland/bg-blue.png');
	background-position: center -100px;
	background-repeat: no-repeat;
	background-size: 100%;
	padding-top: 200px;
}

.text-primary {
	color: #197aff;
}

.text-dark {
	color: #0a3166;
}

.text-light {
	color: #fff;
}

.new-listings-buttons .swiper-button-next,
.new-listings-buttons .swiper-button-prev {
	position: absolute;
	background: #ffffff;
	border-radius: 20px !important;
	width: 50px !important;
	height: 50px !important;
	left: inherit;
	top: 30% !important;
	opacity: 1;
}

.new-listings-buttons .swiper-button-next {
	left: 23% !important;
}

.new-listings-buttons .swiper-button-prev {
	left: 5% !important;
}

.new-listings-buttons .swiper-button-prev.btn-slide-prev::after {
	font-family: 'Font Awesome 5 Pro';
	content: '\f060';
	color: #0a3166;
	font-size: 23px;
}

.new-listings-buttons .swiper-button-next.btn-slide-next::after {
	font-family: 'Font Awesome 5 Pro';
	content: '\f061';
	color: #0a3166;
	font-size: 23px;
}

.new-listings-buttons .swiper-button-prev.btn-slide-prev:hover,
.new-listings-buttons .swiper-button-next.btn-slide-next:hover {
	background-color: #0a3166;
}

.new-listings-buttons .swiper-button-prev.btn-slide-prev:hover::after,
.new-listings-buttons .swiper-button-next.btn-slide-next:hover::after {
	color: #fff !important;
}

.tags {
	background-color: #ef5da8 !important;
}

a:hover {
	color: #0a3166;
	text-transform: none;
}

.sc-card-article .meta-info .author .avatar,
.sc-card-product .meta-info .author .avatar {
	width: auto !important;
	height: auto !important;
}

.sc-card-product .meta-info .price h5 {
	margin-top: 0;
}

.price span,
.info span {
	font-size: 11px !important;
	color: #0a3166 !important;
}

.price h5,
.info h5 {
	font-size: 12px !important;
	color: #0a3166 !important;
	font-weight: bold !important;
}

.sc-card-product {
	background: #ffffff;
	border-radius: 24px;
	box-shadow: none;
}

.new-listings-button {
	border: 3px solid rgba(10, 49, 102, 0.6);
	box-sizing: border-box;
	border-radius: 20px;
	color: #0a3166;
}

.new-listings-button:hover {
	background: #0a3166 !important;
	border-color: #0a3166 !important;
}

.is-fixed.header_1 {
	background: #d2e5ff !important;
	background-color: #d2e5ff !important;
}

.popular-collections-buttons {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.popular-collections-buttons .swiper-button-next,
.popular-collections-buttons .swiper-button-prev {
	position: relative;
	background: #ffffff;
	border-radius: 20px !important;
	width: 50px !important;
	height: 50px !important;
	left: inherit;
	opacity: 1;
}

.popular-collections-buttons .swiper-button-next {
	margin-top: 3px;
	margin-right: 30px !important;
}

.popular-collections-buttons .swiper-button-prev {
	margin-top: 3px;
	margin-right: 30px !important;
}

.popular-collections-buttons .swiper-button-prev.btn-slide-prev::after {
	font-family: 'Font Awesome 5 Pro';
	content: '\f060';
	color: #0a3166;
	font-size: 23px;
}

.popular-collections-buttons .swiper-button-next.btn-slide-next::after {
	font-family: 'Font Awesome 5 Pro';
	content: '\f061';
	color: #0a3166;
	font-size: 23px;
}

.popular-collections-buttons .swiper-button-prev.btn-slide-prev:hover,
.popular-collections-buttons .swiper-button-next.btn-slide-next:hover {
	background-color: #197aff;
}

.popular-collections-buttons .swiper-button-prev.btn-slide-prev:hover::after,
.popular-collections-buttons .swiper-button-next.btn-slide-next:hover::after {
	color: #fff !important;
}

.popular-collections-button {
	border: 3px solid rgba(255, 255, 255, 0.6);
	box-sizing: border-box;
	border-radius: 20px;
	color: #fff;
}

.popular-collections-button:hover {
	background: #197aff !important;
	border: 3px solid #197aff !important;
}

.bg-blue-bg {
	position: absolute;
	background-image: url('../oceanland/popular-collection-bg.png');
	background-position: left top;
	background-repeat: no-repeat;
	background-size: 100%;
	z-index: 0;
	top: -30%;
	left: -10%;
}

.bg-blue-color {
	position: relative;
	background: #0a3166;
	border-radius: 38px;
	padding: 45px;
	margin-top: 150px;
	z-index: 2;
}

.bg-blue-bucket {
	position: absolute;
	background-image: url('../oceanland/popular-collection-bucket.png');
	background-position: left top;
	background-repeat: no-repeat;
	background-size: 100%;
	z-index: 3;
	top: -20%;
	left: 50%;
}

.bg-blue-container {
	background-image: url('../oceanland/bg-blue.png');
	background-position: center -100px;
	background-repeat: no-repeat;
	background-size: 100%;
	padding: 80px;
	border-radius: 38px;
}

.v-center {
	display: flex;
	align-items: center;
}

.form-inner {
	width: 100%;
}

.form-inner form {
	display: flex;
	justify-content: center;
}

.form-inner form select {
	background-color: #fff !important;
	border-radius: 20px !important;
	height: 50px !important;
	display: inline-flex;
	margin-right: 20px !important;
}

.form-inner .sc-button {
	display: inline-flex;
}

.sc-button.style.arrow::before {
	font-family: 'Font Awesome 5 Pro';
	content: '\f061';
	padding: 5px;
	font-size: 16px;
}

#footer {
	background-image: url('../oceanland/footer-coconut.png');
	background-position: 80% bottom;
	background-repeat: no-repeat;
}

#footer .title-widget,
#footer a,
#footer a:hover,
.widget-menu a,
.widget-menu a:hover {
	color: #0a3166;
	text-decoration: none !important;
}

.widget-social.style-1 a,
.widget-social.style-1 a:hover {
	background-color: #0a3166;
	color: #fff !important;
	text-decoration: none !important;
}

#scroll-top:before {
	background-color: #0a3166;
}

.mobile-button:before,
.mobile-button:after,
.mobile-button span {
	background-color: #197aff;
}

#main-nav-mobi {
	background-image: url('../oceanland/bg-blue.png');
	background-position: center top;
	background-repeat: no-repeat;
	background-size: 100%;
}

#main-nav-mobi a,
#main-nav-mobi a:hover {
	color: #0a3166 !important;
}

.widget p,
.widget .title-widget {
	color: #0a3166 !important;
}

.widget-menu ul li a::after {
	background-color: #0a3166 !important;
}

.flat-tabs.items .content-tab .content-inner .btn-sort-by,
.question-form input[type='text'] {
	background: #ffffff;
	border-radius: 16px;
}

.btn-sort-by,
.btn-sort-by span,
.dropdown ul,
.dropdown li span,
.btn-sort-by.dropdown > a,
.btn-sort-by.dropdown ul li span {
	color: #0a3166;
	font-weight: normal;
}

.btn-sort-by.dropdown > a::after {
	color: #0a3166;
	font-weight: normal;
}

.dropdown > a {
	min-width: 219px;
}

.dropdown ul,
.dropdown li.active,
.dropdown ul,
.dropdown li:hover,
.dropdown-item:hover {
	background-color: #eeeeee !important;
	border-radius: 0 !important;
}

.flat-tabs.items .content-tab .content-inner .option .view .btn-sort-by ul li {
	background: #ffffff;
}

.btn-sort-by.dropdown ul {
	top: 78%;
}

.dropdown ul {
	box-shadow: none;
}

.question-form {
	position: relative;
	top: auto;
	transform: none;
	left: auto;
}

body,
button,
input,
select,
textarea {
	color: #0a3166;
	font-weight: normal;
}

.question-form input[type='text'] {
	background: #fff;
	color: #0a3166;
}

input,
input::-webkit-input-placeholder {
	font-size: 16px !important;
	color: #0a3166 !important;
}

.side-bar.item {
	border-radius: 16px;
}

.header-widget-filter .title-widget,
.caption {
	color: #197aff !important;
}

.noUi-horizontal .noUi-handle,
.noUi-connect,
.noUi-horizontal .noUi-handle::after {
	background-color: #197aff !important;
}

.slider-labels .title {
	color: #0a3166;
	font-weight: bold;
}

#footer.style-2 {
	border-radius: 38px 38px 0px 0px !important;
	margin: 0 20px 0 20px;
}

.side-bar.item .divider {
	background-color: #f2f8ff;
}

.side-bar.item .widget-category.boder-bt {
	border-color: #f2f8ff;
}

.right-axe {
	position: absolute;
	right: 0;
	top: 0;
}

.loadmore {
	border: 3px solid rgba(10, 49, 102, 0.6) !important;
	box-sizing: border-box !important;
	border-radius: 20px !important;
	color: #0a3166 !important;
	background: none !important;
}

.loadmore:hover {
	background: #0a3166 !important;
	border-color: #0a3166 !important;
}

.page-container {
	background: #ffffff;
	border-radius: 34px;
	padding: 80px;
	margin-top: 50px;
}

.back-button-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	font-size: 16px;
}

.back-button {
	border: 1px solid rgba(10, 49, 102, 0.6);
	box-sizing: border-box;
	border-radius: 20px;
	width: 58px;
	height: 49px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	font-size: 14px;
	font-weight: normal;
	padding: 16px 20px 15px;
}

.back-button:hover,
.back-button:hover::after {
	color: #fff !important;
	background-color: #197aff !important;
	border-color: #197aff !important;
}

.back-button:hover > span::after {
	color: #fff !important;
}

.back-button span::after {
	font-family: 'Font Awesome 5 Pro';
	content: '\f060';
	color: #0a3166;
	font-size: 14px;
	font-weight: normal;
}

.nft-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.nft-type {
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 700;
	font-size: 13px;
	line-height: 16px;
	color: #197aff;
	background: rgba(25, 122, 255, 0.08);
	border-radius: 8px;
	width: auto;
	padding: 8px 10px 6px;
	margin-bottom: 15px;
	display: inline-block;
}

.nft-title {
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	line-height: 59px;
	letter-spacing: -0.03em;
	color: #0a3166;
}

.nft-description {
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 30px;
	color: #0a3166;
}

.sc-card-nft {
	background: #ffffff;
	border-radius: 24px;
	box-shadow: none;
}

.sc-card-nft {
	border-radius: 20px;
	margin-top: 20px;
	margin-bottom: 25px;
	overflow: hidden;
	transition: all 0.3s ease-in-out;
}

.sc-card-nft .meta-info {
	margin-bottom: 4px;
}

.sc-card-nft .meta-info,
.sc-card-nft .meta-info .author {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.sc-card-nft .meta-info .author {
	justify-content: flex-start;
}

.sc-card-nft .meta-info .author {
	background: #f2f8ff;
	border-radius: 16px;
	width: 45%;
	padding: 16px 24px;
}

.sc-card-nft .meta-info .price {
	background: #f2f8ff;
	border-radius: 16px;
	width: 45%;
	padding: 16px 24px;
}

.sc-card-nft .meta-info .author .avatar {
	width: auto;
	height: auto;
}

.sc-card-nft .meta-info .author .avatar {
	max-width: 24px;
	max-height: 25px;
	border-radius: 15px;
	overflow: hidden;
	margin-right: 12px;
	flex-shrink: 0;
}

.nft-details {
	background: #f2f8ff;
	border-radius: 16px;
	width: 100%;
	padding: 16px 24px;
	border-radius: 20px;
	margin-bottom: 25px;
	overflow: hidden;
	transition: all 0.3s ease-in-out;
	margin-left: auto;
	margin-right: auto;
}

.nft-details .col-4 span {
	font-size: 11px !important;
	color: #0a3166 !important;
}

.nft-details .col-4 h5 {
	font-size: 12px !important;
	color: #0a3166 !important;
	font-weight: bold !important;
	margin-top: 3px;
}

.header_1 .header_avatar .avatar_popup {
	background: #ffffff;
	box-shadow: 0px 28px 64px rgba(0, 0, 0, 0.08);
	border-radius: 18px;
}

.header_1 .header_avatar .avatar_popup p,
.header_1 .header_avatar .avatar_popup .links > a span,
.header_1 .header_avatar .avatar_popup .links > a:hover {
	color: #0a3166;
}

.sc-button.style span::before {
	font-size: 17px;
}

p {
	color: #47648c;
}

.color-popup {
	color: #0a3166;
}

textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'] {
	background: #f2f8ff;
	border: 2px solid #ebf1f7;
	border-radius: 20px;
	padding: 18px 20px 17px;
	color: #0a3166;
}

.modal .sc-button {
	text-transform: uppercase;
}

.modal input {
	background: #f2f8ff;
	border: 2px solid #ebf1f7;
	border-radius: 20px;
	padding: 18px 20px 17px;
	color: #0a3166;
}

.modal-content .close span {
	color: #1c1b1f;
	font-size: 32px;
	font-weight: normal;
}

.modal-content .close {
	padding: 16px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 48px;
	height: 48px;
	right: -5px;
}

.swap-dropdown-toggle {
	width: 100%;
	font-size: 14px;
	background: #f2f8ff;
	border: 2px solid #ebf1f7;
	border-radius: 20px;
}

.swap-dropdown-toggle button,
.swap-dropdown-toggle button:hover {
	background: transparent;
	border: none;
	padding: 18px 20px 17px;
	color: #0a3166;
	width: 100%;
	font-size: 14px;
	text-align: right;
}

.swap-dropdown-toggle .dropdown {
	width: 100%;
}

.swap-dropdown-toggle .dropdown-item {
	background: none;
	border: none;
	color: #0a3166;
	width: 100%;
	font-size: 14px;
}

.swap-dropdown-toggle .dropdown-menu {
	border: none;
	width: 100%;
	background: #f2f8ff;
	color: #0a3166;
	width: 100%;
	font-size: 14px;
}

.swap-dropdown-toggle input {
	width: 100%;
	height: 20px;
	margin: 0;
	padding: 0;
	border: 0;
	background: initial;
	outline: none;
	border-top-style: hidden;
	border-right-style: hidden;
	border-left-style: hidden;
	border-bottom-style: groove;
}

.swap-dropdown-toggle input:focus {
	outline: none !important;
}

.btn-danger:hover {
	color: #fff !important;
	background-color: #d9534f !important;
	border-color: #d9534f !important;
}

.package-list-0 {
	padding-bottom: 0 !important;
}

.package-list-1 {
	padding: 0 !important;
	padding-top: 0 !important;
}

.package-list-2 {
	padding-top: 0 !important;
}

.booster-package-list-0 {
	padding-bottom: 0 !important;
}

.booster-package-list-1 {
	padding: 0 !important;
	padding-top: 0 !important;
}

.booster-package-list-2 {
	padding-top: 0 !important;
}

.button-style {
	left: calc(50% - calc(1rem / 2));
}

.span-style {
	margin-right: 1rem;
}

.sc-button {
	border-radius: 30px !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

@media only screen and (max-width: 1818px) {
	.header_1 #main-nav {
		left: 16% !important;
	}

	.flat-search-btn {
		right: 0 !important;
	}

	/*.flat-tabs.items .content-tab .content-inner .market-list.content-item .col-item {
		width: calc(20% - 30px) !important;
	}*/

	.col-xl-3 {
		flex: 0 0 20%;
		max-width: 20%;
	}
}

@media only screen and (max-width: 1565px) {
	.sc-btn-top.mg-r-12 {
		margin-right: 10px;
	}

	.flat-title-page.style2 .overlay-bottom {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url('../oceanland/overlay-bottom.png');
		background-position: center 72%;
		background-repeat: no-repeat;
		background-size: 100%;
		z-index: 1;
	}

	.flat-title-page.style2 .overlay-coconut {
		position: absolute;
		bottom: 23%;
		right: 12%;
		width: 277px;
		height: 277px;
		background-image: url('../oceanland/overlay-coconut.png');
		background-position: right 73%;
		background-repeat: no-repeat;
		background-size: 25%;
		z-index: 2;
	}

	.flat-title-page.style2 {
		height: 900px;
	}

	.sc-box-icon-bg {
		top: -15%;
	}

	.sc-box-icon {
		text-align: center;
		display: flex;
		align-items: center;
		flex-direction: column;
	}
}

@media only screen and (max-width: 1466px) {
}

@media only screen and (max-width: 1450px) {
}

@media only screen and (max-width: 1199px) {
	.flat-search-btn {
		right: 11% !important;
	}

	.today-pick-tab .content-tab .content-inner .sc-card-product,
	.sc-box-icon {
		width: 100%;
		margin-left: 0;
	}

	.sc-btn-top .sc-button {
		width: 36px;
		height: 36px;
	}

	.sc-button.style span::before {
		font-size: 16px;
		top: 18% !important;
		left: 50% !important;
		transform: translateX(-50%) !important;
	}
}

@media only screen and (max-width: 991px) {
	.swiper-slide {
		width: 100% !important;
	}

	.new-listings-button {
		width: 100%;
		text-align: center;
	}

	.mt-5 {
		margin-top: 1rem !important;
	}

	.bg-blue-color {
		margin-top: 0;
	}

	.bg-blue-bg,
	.bg-blue-bucket {
		display: none;
	}

	.popular-collections-button {
		width: 100%;
		text-align: center;
	}

	.flat-title-page.style3 .mainslider.home .wrap-image,
	.home.s1 .wrap-image,
	.flat-title-page.style2 .shape.item-w-22,
	.swiper-pagination-bullets,
	.swiper-button-prev,
	.swiper-button-next {
		display: none !important;
	}

	.mt-5 {
		margin-top: 0 !important;
	}

	.page-title-heading {
		margin-top: 3rem !important;
	}

	.content-inner:first-child {
		padding-left: 0 !important;
		position: relative !important;
		z-index: 4 !important;
		display: flex !important;
		justify-content: center !important;
		flex-direction: row !important;
	}

	.flat-tabs .content-inner {
		padding-left: 0 !important;
		position: relative !important;
		z-index: 4 !important;
		display: flex !important;
		justify-content: center !important;
		flex-direction: column !important;
		padding: 0 !important;
		margin: 30px !important;
	}

	.widget-logo .logo-footer {
		margin-top: 30px;
	}

	.btn-sort-by {
		max-width: 100% !important;
	}

	.flat-tabs.items .content-tab {
		padding-top: 0;
	}

	.dropdown > a {
		min-width: 318px;
	}

	.page-container {
		margin-bottom: 40px;
		padding: 40px;
	}

	.sc-card-nft .meta-info .author {
		width: 50%;
	}

	.content-right img {
		width: 100% !important;
		margin-top: 30px !important;
	}
}

@media (max-width: 991px) and (min-width: 767px) {
}

@media only screen and (max-width: 767px) {
	.sc-box-icon,
	.style-2 .sc-box-icon,
	.explore-tab .content-tab .content-inner .sc-card-product {
		width: 100%;
	}

	#site-logo-inner {
		top: 40px !important;
	}

	.mobile-button {
		top: 32px !important;
	}

	.bg-blue-container {
		display: none;
	}

	#footer {
		text-align: center !important;
		margin: 0 auto;
	}

	.widget-social {
		display: flex;
		justify-content: center;
		margin-bottom: 15px;
	}

	#footer.style-2,
	#footer {
		padding: 0 !important;
	}

	.pad-b-74.mobie-style,
	.tf-section.mobie-style {
		padding-bottom: 20px !important;
		margin-top: 170px;
	}

	.tf-section.bg-blue {
		background-position: center 100px;
	}

	.sc-card-product {
		box-shadow: 0px 3px 16px rgb(47 83 109 / 12%);
	}

	.tf-item.tf-section {
		padding-bottom: 0;
	}

	.flat-tabs.items .content-tab .content-inner .market-list.content-item .col-item {
		width: calc(100% - 30px) !important;
	}

	.col-xl-3 {
		flex: 0 0 100%;
		max-width: 100%;
	}

	.sc-button.style.wallet span {
		padding-left: 0;
	}

	.sc-button.style.wallet span::before {
		display: none;
	}
}

@media only screen and (max-width: 575px) {
	.modal-body img {
		display: none;
	}
}

@media only screen and (max-width: 549px) {
}

@media only screen and (max-width: 451px) {
}

@media only screen and (max-width: 400px) {
}

#heros-section {
	position: relative;
}

#scroll-down {
	position: absolute;
	left: 50%;
	bottom: 5%;
	z-index: 999;
}

.arrow-down {
	width: 60px;
	height: 40px;
	position: absolute;
	-webkit-animation: arrow 0.5s 1s infinite ease-out alternate;
}

.arrow-down:hover {
	-webkit-animation-play-state: paused;
}

.left {
	position: absolute;
	height: 10px;
	width: 40px;
	background: #0a3166;
	-webkit-transform: rotate(240deg);
	top: 10px;
	left: 10px;
	-webkit-border-radius: 4px;
	-webkit-transform-origin: 5px 50%;
	-webkit-animation: leftArrow 0.5s 1s infinite ease-out alternate;
}

.right {
	position: absolute;
	height: 10px;
	width: 40px;
	background: #0a3166;
	-webkit-transform: rotate(-60deg);
	top: 10px;
	left: 10px;
	-webkit-border-radius: 4px;
	-webkit-transform-origin: 5px 50%;
	-webkit-animation: rightArrow 0.5s 1s infinite ease-out alternate;
}

@keyframes arrow {
	0% {
		bottom: 0px;
	}
	100% {
		bottom: 40px;
	}
}

@keyframes leftArrow {
	0% {
	}
	100% {
		-webkit-transform: rotate(225deg);
	}
}

@keyframes rightArrow {
	0% {
	}
	100% {
		-webkit-transform: rotate(-45deg);
	}
}

.sc-box-icon-bg {
	display: none;
}

*:focus,
.btn,
.btn:focus,
.btn-secondary,
.btn-secondary:focus,
.dropdown-toggle,
.dropdown-toggle:focus {
	outline: none !important;
}

button:focus {
	outline: 0 !important;
}

.page-container {
	padding: 40px;
}
